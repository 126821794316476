
import 'vue-class-component'
import {Component, Vue, Watch} from 'vue-property-decorator';
import FenceItem from "@/components/editor/FenceItem.vue";
import {
  FenceCoverColor,
  FenceCoverType,
  FenceItemDoor,
  FenceItemModel,
  FenceItemType,
  FencePlate,
  FencePlateCover,
  FencePlatePattern,
  FencePost,
  FencePostCover,
  FencePostType,
  ProductHelper,
  ProductModel,
  Properties
} from "../../types/editor";
import InputDropdown, {InputDropdownOption} from "@/components/inputs/InputDropdown.vue";
import InputSwitch from "@/components/inputs/InputSwitch.vue";
import {EventBus} from '@/plugins/event-bus';
import {ViewPort} from "@/util/configurator";
import TopViewPort from "@/components/editor/TopViewPort.vue";
import PopoverSettingsModal from "@/components/popovers/PopoverSettingsModal.vue";
import PopupModal from "@/components/popup/PopupModal.vue";
import PdfMapPrinter from "@/components/pdf/PdfMapPrinter.vue";
import FenceCoverDialog from "@/components/editor/FenceCoverDialog.vue";

Vue.filter('toCurrency', function (value) {
    if (isNaN(parseFloat(value))) {
        return value;
    }
    var formatter = new Intl.NumberFormat('de', {
        style: 'currency',
        currency: 'EUR'
    });
    return formatter.format(value);
});

//gsap.registerPlugin(CustomEase);

interface PriceModel {
  fenceLength: number,
  postCount: number,
  plateCount: number,
  postCoverCount: number,
  roofCapsCount: number,
  deliverConcrete: boolean,
  deliverColor: boolean,
  pricePlates: number,
  pricePosts: number,
  priceCoverAndOther: number,
  priceConcreteAndColor: number
}

@Component({
  components: {
    FenceCoverDialog,
    PopoverSettingsModal,
    PdfMapPrinter,
    PopupModal,
    TopViewPort,
    InputSwitch,
    InputDropdown,
    FenceItem}
})
export default class Configurator extends Vue {

  initialized = false;

  fenceCoverDialogOpened = false;
  coverType: FenceCoverType = FenceCoverType.pyramid;
  coverColor = FenceCoverColor.anthrazit;
  coverDialogStep = 0;

  enableDebugging = false;

  fenceItems: Array<FenceItemModel> = [];

  cachedFenceItems: Array<FenceItemModel> = [];

  cachedFenceItemRebuildLoopId = -1;

  selectedFenceItemIndex = 0;

  lastX = 0;
  lastY = 0;

  ralRules = [
    value => !!value || 'Obligatorisch.'
  ];

  viewPort: ViewPort = {
    x: 0,
    renderedX: 0,
    y: 0,
    renderedY: 0,
    rotate: 0,
    targetRotate: 0,
    size: {
      height: 0,
      width: 0
    },
    vXRotationAdjustment: 0,
    vYRotationAdjustment: 0
  }

  lastPosX = 0;
  lastPosY = 0;
  itemsChanged = false;

  loadingPrices = false;

  prices: PriceModel = {
    fenceLength: 0,
    postCount: 0,
    plateCount: 0,
    postCoverCount: 0,
    roofCapsCount: 0,
    deliverConcrete: false,
    deliverColor: false,
    pricePlates: 0,
    pricePosts: 0,
    priceCoverAndOther: 0,
    priceConcreteAndColor: 0
  }

  cart = {};

  sidebarDrawerOpen = true;

  fenceItemWidth = 630;
  fenceItemHeight = 800;

  plateHeightOptions = Properties.getPlateHeightOptions();

  currentPlateHeight = 36;

  async sendCartItems(): Promise<void> {
    var data = btoa(JSON.stringify(this.cart));
    window.location.href = "https://www.betonzaun.de/warenkorb/?send-to-cart="+data;
  }

  buildCachedFenceItems(): void {
    let posX = -this.viewPort.x;
    let posY = -this.viewPort.y;
    if (posX !== this.lastPosX || posY !== this.lastPosY || this.itemsChanged === true) {
      this.cachedFenceItems = this.fenceItems.filter(value => this.isItemVisible(value));
      this.lastPosX = posX;
      this.lastPosY = posY;
      this.itemsChanged = false;
    }
  }

  isItemVisible(item: FenceItemModel): boolean {
    if (item != null) {
      let posX = -this.viewPort.x;
      let posY = -this.viewPort.y;
      let bWidth = (this.viewPort.size.width) * 4;
      let bHeight = (this.viewPort.size.height) * 4;
      var vBounds = {
        yMin: -(bHeight / 2.0),
        yMax: bHeight / 2.0,
        xMin: -(bWidth / 2.0),
        xMax: bWidth / 2.0
      };
      return item.position.end.x >= (posX + vBounds.xMin) && item.position.start.x <= (posX + vBounds.xMax) && item.position.end.y >= (posY + vBounds.xMin) && item.position.start.y <= (posY + vBounds.xMax);
    }
    return false;
  }

  getMinimumYItem(): FenceItemModel {
    let minimumStartY = this._.minBy(this.fenceItems, function (o) {
      return o.position.start.y
    });
    let minimumEndY = this._.minBy(this.fenceItems, function (o) {
      return o.position.end.y
    });
    if (minimumStartY != null) {
      if (minimumEndY != null) {
        if (minimumEndY.position.y < minimumStartY.position.y) {
          return minimumEndY;
        } else {
          return minimumStartY;
        }
      } else {
        return minimumStartY;
      }
    } else if (minimumEndY != null) {
      return minimumEndY;
    }
    return null;
  }

  getMinimumXItem(): FenceItemModel {
    let minimumStartX = this._.minBy(this.fenceItems, function (o) {
      return o.position.start.x
    });
    let minimumEndX = this._.minBy(this.fenceItems, function (o) {
      return o.position.end.x
    });
    if (minimumStartX != null) {
      if (minimumEndX != null) {
        if (minimumEndX.position.x < minimumStartX.position.x) {
          return minimumEndX;
        } else {
          return minimumStartX;
        }
      } else {
        return minimumStartX;
      }
    } else if (minimumEndX != null) {
      return minimumEndX;
    }
    return null;
  }

  getMinimumX(): number {
    let minimumXItem = this.getMinimumXItem();
    return (minimumXItem != null && minimumXItem.position != null) ? Math.min(minimumXItem.position.start.x, minimumXItem.position.end.x) : 0;
  }

  getMinimumY(): number {
    let minimumXItem = this.getMinimumYItem();
    return (minimumXItem != null && minimumXItem.position != null) ? Math.min(minimumXItem.position.start.y, minimumXItem.position.end.y) : 0;
  }

  getLeftAddButtonX(): number {
    return -(this.fenceItemWidth / 2);
  }

  getMaximumYItem(): FenceItemModel {
    let maximumStartY = this._.maxBy(this.fenceItems, function (o) {
      return o.position.start.y
    });
    let maximumEndY = this._.maxBy(this.fenceItems, function (o) {
      return o.position.end.y
    });
    if (maximumStartY != null) {
      if (maximumEndY != null) {
        if (maximumEndY.position.y > maximumStartY.position.y) {
          return maximumEndY;
        } else {
          return maximumStartY;
        }
      } else {
        return maximumStartY;
      }
    } else if (maximumEndY != null) {
      return maximumEndY;
    }
    return null;
  }

  getMaximumXItem(): FenceItemModel {
    let maximumStartX = this._.maxBy(this.fenceItems, function (o) {
      return o.position.start.x
    });
    let maximumEndX = this._.maxBy(this.fenceItems, function (o) {
      return o.position.end.x
    });
    if (maximumStartX != null) {
      if (maximumEndX != null) {
        if (maximumEndX.position.x > maximumStartX.position.x) {
          return maximumEndX;
        } else {
          return maximumStartX;
        }
      } else {
        return maximumStartX;
      }
    } else if (maximumEndX != null) {
      return maximumEndX;
    }
    return null;
  }

  getMaximumX(): number {
    let maximumXItem = this.getMaximumXItem();
    return (maximumXItem != null && maximumXItem.position != null) ? Math.max(maximumXItem.position.start.x, maximumXItem.position.end.x) : 0;
  }

  getMaximumY(): number {
    let maximumYItem = this.getMaximumYItem();
    return (maximumYItem != null && maximumYItem.position != null) ? Math.max(maximumYItem.position.start.y, maximumYItem.position.end.y) : 0;
  }

  get vXRotationAdjustment(): number {
    let amt = (this.fenceItemWidth / 2);
    let currentFenceItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
    if (currentFenceItem != null && currentFenceItem.type === FenceItemType.FenceDoor) {
      amt = 323 / 2;
    }
    if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
      return -amt;
    } else if (this.viewPort.targetRotate === 180 || this.viewPort.targetRotate === 90) {
      return amt;
    }
    return -500;
  }

  get vYRotationAdjustment(): number {
    let amt = (this.fenceItemWidth / 2);
    let currentFenceItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
    if (currentFenceItem != null && currentFenceItem.type === FenceItemType.FenceDoor) {
      amt = 323 / 2;
    }
    if (this.viewPort.targetRotate === 90) {
      return -amt;
    } else if (this.viewPort.targetRotate === 180 || this.viewPort.targetRotate === 270) {
      return amt;
    }
    return -500;
  }

  get selectedFenceItem(): FenceItemModel {
    return this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
  }

  get hasItemToLeft(): boolean {
    return this.getNextFenceItemToLeft() !== null;
  }

  get hasItemToRight(): boolean {
    return this.getNextFenceItemToRight() !== null;
  }

  getNextFenceItemToLeftFrom(item: FenceItemModel): FenceItemModel | null {
    if (item != null) {
      if (item.leftFenceItem != null) {
        return item.leftFenceItem;
      }
      let currentPosition = 0;
      let currentAxis = '';
      let rightItems = [];
      let rotate = this.getAngle(item);
      if (rotate === 270) {
        currentAxis = '+y';
        rightItems = this.fenceItems.filter(value => value.position.end.y > item.position.end.y && value.position.end.x === item.position.end.x && Math.abs(value.position.end.y - item.position.end.y) <= this.fenceItemWidth);
        currentPosition = item.position.end.y;
      } else if (rotate === 360 || rotate === 0) {
        currentAxis = '+x';
        rightItems = this.fenceItems.filter(value => value.position.end.x < item.position.end.x && value.position.end.y === item.position.end.y && Math.abs(value.position.end.x - item.position.end.x) <= this.fenceItemWidth);
        currentPosition = item.position.end.x;
      } else if (rotate === 180) {
        currentAxis = '-x';
        rightItems = this.fenceItems.filter(value => value.position.end.x > item.position.end.x && value.position.end.y === item.position.end.y && Math.abs(value.position.end.x - item.position.end.x) <= this.fenceItemWidth);
        currentPosition = item.position.end.x;
      } else if (rotate === 90) {
        currentAxis = '-y';
        rightItems = this.fenceItems.filter(value => value.position.end.y < item.position.end.y && value.position.end.x === item.position.end.x && Math.abs(value.position.end.y - item.position.end.y) <= this.fenceItemWidth);
        currentPosition = item.position.end.y;
      }
      let nearestItem = null;
      if (rightItems.length > 1) {
        nearestItem = null;
        let checkPosition = 0;
        let diff = 0;
        for (let i = 0; i < rightItems.length; i++) {
          if (nearestItem === null) {
            nearestItem = rightItems[i];
            diff = Number.MAX_VALUE;
          }
          if (currentAxis === '-y') {
            checkPosition = nearestItem.position.end.y;
          } else if (currentAxis === '+y') {
            checkPosition = nearestItem.position.end.y;
          } else if (currentAxis === '-x') {
            checkPosition = nearestItem.position.end.x;
          } else if (currentAxis === '+x') {
            checkPosition = nearestItem.position.end.x;
          }
          let newDiff = Math.abs(currentPosition - checkPosition);
          if (newDiff < diff) {
            nearestItem = rightItems[i];
            diff = newDiff;
          }
        }
      } else if (rightItems.length === 1) {
        nearestItem = rightItems[0];
      }
      /*if(nearestItem == null || Math.abs(nearestItem.position.start.x - item.position.start.x) > this.fenceItemWidth) {
         let nextItem = this.fenceItems.find(value => value.position.start.x === currentPositionX && value.id !== item.id && value.position.start.y !== item.position.start.y);
         if(nextItem != null) {
           return nextItem;
         }
         return null;
       }else
         return nearestItem;*/
      item.leftFenceItem = nearestItem;
      return nearestItem;
    } else {
      return null;
    }
  }

  getNextFenceItemToLeft(): FenceItemModel | null {
    return this.getNextFenceItemToLeftFrom(this.fenceItems.find(value => value.id === this.selectedFenceItemIndex));
  }

  getNextFenceItemToRightFrom(item: FenceItemModel): FenceItemModel | null {
    if (item != null) {
      if (item.rightFenceItem != null) {
        return item.rightFenceItem;
      }
      let currentPosition = 0;
      let currentAxis = '';
      let rightItems = [];
      let rotate = this.getAngle(item);
      if (rotate === 270) {
        currentAxis = '-y';
        rightItems = this.fenceItems.filter(value => value.position.start.y < item.position.start.y && value.position.start.x === item.position.start.x && Math.abs(value.position.start.y - item.position.start.y) <= this.fenceItemWidth);
        currentPosition = item.position.start.y;
      } else if (rotate === 360 || rotate === 0) {
        currentAxis = '+x';
        rightItems = this.fenceItems.filter(value => value.position.start.x > item.position.start.x && value.position.start.y === item.position.start.y && Math.abs(value.position.start.x - item.position.start.x) <= this.fenceItemWidth);
        currentPosition = item.position.start.x;
      } else if (rotate === 180) {
        currentAxis = '-x';
        rightItems = this.fenceItems.filter(value => value.position.start.x < item.position.start.x && value.position.start.y === item.position.start.y && Math.abs(value.position.start.x - item.position.start.x) <= this.fenceItemWidth);
        currentPosition = item.position.start.x;
      } else if (rotate === 90) {
        currentAxis = '+y';
        rightItems = this.fenceItems.filter(value => value.position.start.y > item.position.start.y && value.position.start.x === item.position.start.x && Math.abs(value.position.start.y - item.position.start.y) <= this.fenceItemWidth);
        currentPosition = item.position.start.y;
      }
      let nearestItem = null;
      if (rightItems.length > 1) {
        nearestItem = null;
        let checkPosition = 0;
        let diff = 0;
        for (let i = 0; i < rightItems.length; i++) {
          if (nearestItem === null) {
            nearestItem = rightItems[i];
            diff = Number.MAX_VALUE;
          }
          if (currentAxis === '-y') {
            checkPosition = nearestItem.position.start.y;
          } else if (currentAxis === '+y') {
            checkPosition = nearestItem.position.start.y;
          } else if (currentAxis === '-x') {
            checkPosition = nearestItem.position.start.x;
          } else if (currentAxis === '+x') {
            checkPosition = nearestItem.position.start.x;
          }
          let newDiff = Math.abs(currentPosition - checkPosition);
          if (newDiff < diff) {
            nearestItem = rightItems[i];
            diff = newDiff;
          }
        }
      } else if (rightItems.length === 1) {
        nearestItem = rightItems[0];
      }
      /*if(nearestItem == null || Math.abs(nearestItem.position.start.x - item.position.start.x) > this.fenceItemWidth) {
         let nextItem = this.fenceItems.find(value => value.position.start.x === currentPositionX && value.id !== item.id && value.position.start.y !== item.position.start.y);
         if(nextItem != null) {
           return nextItem;
         }
         return null;
       }else
         return nearestItem;*/
      item.rightFenceItem = nearestItem;
      return nearestItem;
    } else {
      return null;
    }
  }

  getNextFenceItemToRight(): FenceItemModel | null {
    return this.getNextFenceItemToRightFrom(this.fenceItems.find(value => value.id === this.selectedFenceItemIndex));
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  callMoveLeft: Function;

  // eslint-disable-next-line @typescript-eslint/ban-types
  callMoveRight: Function;

  moveLeft(): void {
    let targetItem = this.getNextFenceItemToLeft();
    if (targetItem != null) {
      this.selectFenceItem(targetItem);
    }
  }

  moveRight(): void {
    let targetItem = this.getNextFenceItemToRight();
    if (targetItem != null) {
      this.selectFenceItem(targetItem);
    }
  }

  selectFenceItem(targetItem: FenceItemModel): void {
    if (targetItem != null) {
      let targetX = targetItem.position.start.x;
      let targetY = targetItem.position.start.y;
      targetX = -targetX;
      targetY = -targetY;
      this.selectedFenceItemIndex = targetItem.id;
      this.viewPort.x = targetX;
      this.viewPort.y = targetY;
      /*gsap.fromTo(this.viewPort, {
        x: this.viewPort.renderedX,
        y: this.viewPort.renderedY
      }, {
        x: targetX,
        y: targetY,
        duration: 0.2,
        ease: CustomEase.create("custom", "M0,0 C0.02,0.277 0.152,0.587 0.432,0.748 0.832,0.978 1,1 1,1 "),
        onComplete: () => {
          queueMicrotask(this.buildCachedFenceItems);
        }
      })*/
    }
  }

  addFenceItemToLeftDoor(): void {
    if (this.getNextFenceItemToLeft() === null) {
      let currentItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (currentItem != null) {
        let fenceItem = this.createDefaultFenceItem(true);
        fenceItem.type = FenceItemType.FenceDoor;
        fenceItem.door = ProductHelper.getDoor(currentItem.field.totalFenceHeight > 200 ? 200 : 180);
        fenceItem.rightFenceItem = currentItem;
        currentItem.leftFenceItem = fenceItem;
        let margin = 292;
        let angle = Math.abs(this.getAngle(currentItem));
        if (angle == 90 || angle == 270) {
          if (this.viewPort.targetRotate === 270) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let startY = y + margin;
              fenceItem.position.start.y = startY;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;

            } else if (currentItem.position.start.y > currentItem.position.end.y) {
              let endY = currentItem.position.start.y;
              let y = endY + margin;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          } else if (this.viewPort.targetRotate === 90) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let endY = currentItem.position.start.y;
              let y = endY - margin;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 180) {
          if (this.viewPort.targetRotate === 180) {
            if (currentItem.position.start.x > currentItem.position.end.x) {
              let endX = currentItem.position.start.x;
              let x = endX + margin;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = endX;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 360) {
          if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
            if (currentItem.position.start.x < currentItem.position.end.x) {
              let x = currentItem.position.start.x;
              let startX = x - margin;
              fenceItem.position.start.x = startX;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        }
      }
    }
  }

  addFenceItemToLeftForward(): void {
    if (this.getNextFenceItemToLeft() === null) {
      let currentItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (currentItem != null) {
        let fenceItem = this.createDefaultFenceItem();
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.rightFenceItem.field));
        } else {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.field));
        }
        
        // update right post
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field.rightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
          if (fenceItem.field.rightPostCover != null) {
            fenceItem.field.rightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
          }
        } else {
          const newRightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.corner, this.coverColor);
          fenceItem.field.rightPost = newRightPost;
          currentItem.field.leftPost = newRightPost;

          if (fenceItem.field.rightPostCover != null) {
            const newRightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.corner, this.coverColor);
            fenceItem.field.rightPostCover = newRightPostCover;
            currentItem.field.leftPostCover = newRightPostCover;
          }
        }

        // update left post
        fenceItem.field.leftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
        if (fenceItem.field.leftPostCover != null) {
          fenceItem.field.leftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
        }

        let angle = Math.abs(this.getAngle(currentItem));
        if (angle == 90 || angle == 270) {
          if (this.viewPort.targetRotate === 270) {
            if (currentItem.position.start.y > currentItem.position.end.y) {
              let y = currentItem.position.start.y;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x - this.fenceItemWidth;
              fenceItem.position.end.x = currentItem.position.end.x;
              fenceItem.rightFenceItem = currentItem;
              currentItem.leftFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          } else if (this.viewPort.targetRotate === 90) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.start.y;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x + this.fenceItemWidth;
              fenceItem.position.end.x = currentItem.position.end.x;
              fenceItem.rightFenceItem = currentItem;
              currentItem.leftFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 180) {
          if (this.viewPort.targetRotate === 180) {
            if (currentItem.position.start.x > currentItem.position.end.x) {
              let x = currentItem.position.start.x;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y + this.fenceItemWidth;
              fenceItem.position.end.y = currentItem.position.end.y;
              fenceItem.rightFenceItem = currentItem;
              currentItem.leftFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 360) {
          if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
            if (currentItem.position.start.x < currentItem.position.end.x) {
              let x = currentItem.position.start.x;
              let endY = currentItem.position.start.y - this.fenceItemWidth;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = endY;
              fenceItem.position.end.y = currentItem.position.start.y;
              fenceItem.rightFenceItem = currentItem;
              currentItem.leftFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        }
      }
    }
  }

  addFenceItemToLeftBackwards(): void {
    if (this.getNextFenceItemToLeft() === null) {
      let currentItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (currentItem != null) {
        let fenceItem = this.createDefaultFenceItem();
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.rightFenceItem.field));
        } else {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.field));
        }

        // update right post
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field.rightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
          if (fenceItem.field.rightPostCover != null) {
            fenceItem.field.rightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
          }
        } else {
          const newRightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.corner, this.coverColor);
          fenceItem.field.rightPost = newRightPost;
          currentItem.field.leftPost = newRightPost;

          if (fenceItem.field.rightPostCover != null) {
            const newRightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.corner, this.coverColor);
            fenceItem.field.rightPostCover = newRightPostCover;
            currentItem.field.leftPostCover = newRightPostCover;
          }
        }

        // update left post
        fenceItem.field.leftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
        if (fenceItem.field.leftPostCover != null) {
          fenceItem.field.leftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
        }

        let angle = Math.abs(this.getAngle(currentItem));
        if (angle == 90 || angle == 270) {
          if (this.viewPort.targetRotate === 270) {
            if (currentItem.position.start.y > currentItem.position.end.y) {
              let y = currentItem.position.start.y;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x + this.fenceItemWidth;
              fenceItem.position.end.x = currentItem.position.end.x;
              fenceItem.rightFenceItem = currentItem;
              currentItem.leftFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          } else if (this.viewPort.targetRotate === 90) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.start.y;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x - this.fenceItemWidth;
              fenceItem.position.end.x = currentItem.position.end.x;
              fenceItem.rightFenceItem = currentItem;
              currentItem.leftFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 180) {
          if (this.viewPort.targetRotate === 180) {
            if (currentItem.position.start.x > currentItem.position.end.x) {
              let x = currentItem.position.start.x;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y - this.fenceItemWidth;
              fenceItem.position.end.y = currentItem.position.end.y;
              fenceItem.rightFenceItem = currentItem;
              currentItem.leftFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 360) {
          if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
            if (currentItem.position.start.x < currentItem.position.end.x) {
              let x = currentItem.position.start.x;
              let endY = currentItem.position.start.y + this.fenceItemWidth;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = endY;
              fenceItem.position.end.y = currentItem.position.start.y;
              fenceItem.rightFenceItem = currentItem;
              currentItem.leftFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        }
      }
    }
  }

  addFenceItemToLeft(copyFenceItem: FenceItemModel): void {
    if (this.getNextFenceItemToLeft() === null) {
      let currentItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (currentItem != null) {
        let fenceItem = this.createDefaultFenceItem();
        if (copyFenceItem !== undefined && copyFenceItem.field != null) {
          fenceItem.field = JSON.parse(JSON.stringify(copyFenceItem.field));
        } else if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.rightFenceItem.field));
        } else {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.field));
        }

        fenceItem.rightFenceItem = currentItem;
        currentItem.leftFenceItem = fenceItem;

        // update right post
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field.rightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
          if (fenceItem.field.rightPostCover != null) {
            fenceItem.field.rightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
          }
        } else {
          const newRightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.intermediate, this.coverColor);
          fenceItem.field.rightPost = newRightPost;
          currentItem.field.leftPost = newRightPost;

          if (fenceItem.field.rightPostCover != null) {
            const newRightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.intermediate, this.coverColor);
            fenceItem.field.rightPostCover = newRightPostCover;
            currentItem.field.leftPostCover = newRightPostCover;
          }
        }

        // update left post
        fenceItem.field.leftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
        if (fenceItem.field.leftPostCover != null) {
          fenceItem.field.leftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
        }

        let angle = Math.abs(this.getAngle(currentItem));
        if (angle == 90 || angle == 270) {
          if (this.viewPort.targetRotate === 270) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let startY = y + this.fenceItemWidth;
              fenceItem.position.start.y = startY;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;

            } else if (currentItem.position.start.y > currentItem.position.end.y) {
              let endY = currentItem.position.start.y;
              let y = endY + this.fenceItemWidth;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
              this.fenceItems.push(fenceItem);
              setTimeout(() => {this.selectFenceItemById(fenceItem.id);}, 20);
            }
          } else if (this.viewPort.targetRotate === 90) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let endY = currentItem.position.start.y;
              let y = endY - this.fenceItemWidth;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
              this.fenceItems.push(fenceItem);
              setTimeout(() => {this.selectFenceItemById(fenceItem.id);}, 20);
            }
          }
        } else if (angle == 180) {
          if (this.viewPort.targetRotate === 180) {
            if (currentItem.position.start.x > currentItem.position.end.x) {
              let endX = currentItem.position.start.x;
              let x = endX + this.fenceItemWidth;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = endX;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y;
              this.fenceItems.push(fenceItem);
              setTimeout(() => {this.selectFenceItemById(fenceItem.id);}, 20);
            }
          }
        } else if (angle == 360) {
          if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
            if (currentItem.position.start.x < currentItem.position.end.x) {
              let x = currentItem.position.start.x;
              let startX = x - this.fenceItemWidth;
              fenceItem.position.start.x = startX;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y;
              this.fenceItems.push(fenceItem);
              setTimeout(() => {this.selectFenceItemById(fenceItem.id);}, 20);
            }
          }
        }
      }
    }
  }

  addFenceItemToRight(copyFenceItem?: FenceItemModel): void {
    if (this.getNextFenceItemToRight() === null) {
      let currentItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (currentItem != null) {
        let fenceItem = this.createDefaultFenceItem();
        if (copyFenceItem != null && copyFenceItem.field != null) {
          fenceItem.field = JSON.parse(JSON.stringify(copyFenceItem.field));
        } else if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.leftFenceItem.field));
        } else {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.field));
        }
        fenceItem.leftFenceItem = currentItem;
        currentItem.rightFenceItem = fenceItem;

        // update left post
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field.leftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
          if (fenceItem.field.leftPostCover != null) {
            fenceItem.field.leftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
          }
        } else {
          const newLeftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.intermediate, this.coverColor);
          fenceItem.field.leftPost = newLeftPost;
          currentItem.field.rightPost = newLeftPost;

          if (fenceItem.field.leftPostCover != null) {
            const newLeftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.intermediate, this.coverColor);
            fenceItem.field.leftPostCover = newLeftPostCover;
            currentItem.field.rightPostCover = newLeftPostCover;
          }
        }

        // update right post
        fenceItem.field.rightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
        if (fenceItem.field.rightPostCover != null) {
          fenceItem.field.rightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
        }

        let angle = Math.abs(this.getAngle(currentItem));
        if (angle == 90 || angle == 270) {
          if (this.viewPort.targetRotate === 270) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let endY = y + this.fenceItemWidth;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;

            } else if (currentItem.position.start.y > currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let endY = y - this.fenceItemWidth;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
              this.fenceItems.push(fenceItem);
              setTimeout(() => {this.selectFenceItemById(fenceItem.id);}, 20);
            }
          } else if (this.viewPort.targetRotate === 90) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let endY = y + this.fenceItemWidth;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
              this.fenceItems.push(fenceItem);
              setTimeout(() => {this.selectFenceItemById(fenceItem.id);}, 20);
            }
          }
        } else if (angle == 180) {
          if (this.viewPort.targetRotate === 180) {
            if (currentItem.position.start.x > currentItem.position.end.x) {
              let x = currentItem.position.end.x;
              let endX = x - this.fenceItemWidth;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = endX;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y;
              this.fenceItems.push(fenceItem);
              setTimeout(() => {this.selectFenceItemById(fenceItem.id);}, 20);
            }
          }
        } else if (angle == 360) {
          if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
            if (currentItem.position.start.x < currentItem.position.end.x) {
              let startX = currentItem.position.end.x;
              let x = startX + this.fenceItemWidth;
              fenceItem.position.start.x = startX;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y;
              this.fenceItems.push(fenceItem);
              setTimeout(() => {this.selectFenceItemById(fenceItem.id);}, 20);
            }
          }
        }
      }
    }
  }

  addFenceItemToRightForward(): void {
    if (this.getNextFenceItemToRight() === null) {
      let currentItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (currentItem != null) {
        let fenceItem = this.createDefaultFenceItem();
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.leftFenceItem.field));
        } else {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.field));
        }

        // update left post
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field.leftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
          if (fenceItem.field.leftPostCover != null) {
            fenceItem.field.leftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
          }
        } else {
          const newLeftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.corner, this.coverColor);
          fenceItem.field.leftPost = newLeftPost;
          currentItem.field.rightPost = newLeftPost;

          if (fenceItem.field.leftPostCover != null) {
            const newLeftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.corner, this.coverColor);
            fenceItem.field.leftPostCover = newLeftPostCover;
            currentItem.field.rightPostCover = newLeftPostCover;
          }
        }

        // update right post
        fenceItem.field.rightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
        if (fenceItem.field.rightPostCover != null) {
          fenceItem.field.rightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
        }

        let angle = Math.abs(this.getAngle(currentItem));
        if (angle == 90 || angle == 270) {
          if (this.viewPort.targetRotate === 270) {
            if (currentItem.position.start.y > currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x-this.fenceItemWidth;
              fenceItem.leftFenceItem = currentItem;
              currentItem.rightFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          } else if (this.viewPort.targetRotate === 90) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let endY = y + this.fenceItemWidth;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x+this.fenceItemWidth;
              fenceItem.leftFenceItem = currentItem;
              currentItem.rightFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 180) {
          if (this.viewPort.targetRotate === 180) {
            if (currentItem.position.start.x > currentItem.position.end.x) {
              let x = currentItem.position.end.x;
              let endX = x - this.fenceItemWidth;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y+this.fenceItemWidth;
              fenceItem.leftFenceItem = currentItem;
              currentItem.rightFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 360) {
          if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
            if (currentItem.position.start.x < currentItem.position.end.x) {
              let x = currentItem.position.end.x;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y-this.fenceItemWidth;
              fenceItem.leftFenceItem = currentItem;
              currentItem.rightFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        }
      }
    }
  }

  addFenceItemToRightBackwards(): void {
    if (this.getNextFenceItemToRight() === null) {
      let currentItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (currentItem != null) {
        let fenceItem = this.createDefaultFenceItem();
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.leftFenceItem.field));
        } else {
          fenceItem.field = JSON.parse(JSON.stringify(currentItem.field));
        }

        // update left post
        if (currentItem.type == FenceItemType.FenceDoor) {
          fenceItem.field.leftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
          if (fenceItem.field.leftPostCover != null) {
            fenceItem.field.leftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
          }
        } else {
          const newLeftPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.corner, this.coverColor);
          fenceItem.field.leftPost = newLeftPost;
          currentItem.field.rightPost = newLeftPost;

          if (fenceItem.field.leftPostCover != null) {
            const newLeftPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.corner, this.coverColor);
            fenceItem.field.leftPostCover = newLeftPostCover;
            currentItem.field.rightPostCover = newLeftPostCover;
          }
        }

        // update right post
        fenceItem.field.rightPost = this.getPost(fenceItem.field.series, fenceItem.field.fenceHeight, FencePostType.end, this.coverColor);
        if (fenceItem.field.rightPostCover != null) {
          fenceItem.field.rightPostCover = this.getPostCover(fenceItem.field.series, this.coverType, FencePostType.end, this.coverColor);
        }

        let angle = Math.abs(this.getAngle(currentItem));
        if (angle == 90 || angle == 270) {
          if (this.viewPort.targetRotate === 270) {
            if (currentItem.position.start.y > currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x+this.fenceItemWidth;
              fenceItem.leftFenceItem = currentItem;
              currentItem.rightFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          } else if (this.viewPort.targetRotate === 90) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = y;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x-this.fenceItemWidth;
              fenceItem.leftFenceItem = currentItem;
              currentItem.rightFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 180) {
          if (this.viewPort.targetRotate === 180) {
            if (currentItem.position.start.x > currentItem.position.end.x) {
              let x = currentItem.position.end.x;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y-this.fenceItemWidth;
              fenceItem.leftFenceItem = currentItem;
              currentItem.rightFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 360) {
          if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
            if (currentItem.position.start.x < currentItem.position.end.x) {
              let x = currentItem.position.end.x;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y+this.fenceItemWidth;
              fenceItem.leftFenceItem = currentItem;
              currentItem.rightFenceItem = fenceItem;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  onAddFenceItemToRight(e: PointerEvent): void {
    if (e != null && e.target != null) {
      if ((e.target as HTMLElement).closest('[data-door]')) {
        this.addFenceItemToRightDoor();
      } else {
        this.addFenceItemToRight();
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
  onAddFenceItemToLeft(e: any): void {
    if (e != null && e.target != null) {
      if ((e.target as HTMLElement).closest('[data-door]')) {
        this.addFenceItemToLeftDoor();
      } else {
        this.addFenceItemToLeft(e);
      }
    }
  }

  addFenceItemToRightDoor(): void {
    if (this.getNextFenceItemToRight() === null) {
      let currentItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (currentItem != null) {
        let fenceItem = this.createDefaultFenceItem(true);
        fenceItem.type = FenceItemType.FenceDoor;
        fenceItem.door = ProductHelper.getDoor(currentItem.field.totalFenceHeight > 200 ? 200 : 180);
        fenceItem.leftFenceItem = currentItem;
        currentItem.rightFenceItem = fenceItem;
        let margin = 292;
        let angle = Math.abs(this.getAngle(currentItem));
        if (angle == 90 || angle == 270) {
          if (this.viewPort.targetRotate === 270) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let endY = y + margin;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
            } else if (currentItem.position.start.y > currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let endY = y - margin;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          } else if (this.viewPort.targetRotate === 90) {
            if (currentItem.position.start.y < currentItem.position.end.y) {
              let y = currentItem.position.end.y;
              let endY = y + margin;
              fenceItem.position.start.y = y;
              fenceItem.position.end.y = endY;
              fenceItem.position.start.x = currentItem.position.start.x;
              fenceItem.position.end.x = currentItem.position.end.x;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 180) {
          if (this.viewPort.targetRotate === 180) {
            if (currentItem.position.start.x > currentItem.position.end.x) {
              let x = currentItem.position.end.x;
              let endX = x - margin;
              fenceItem.position.start.x = x;
              fenceItem.position.end.x = endX;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        } else if (angle == 360) {
          if (this.viewPort.targetRotate === 0 || this.viewPort.targetRotate === 360) {
            if (currentItem.position.start.x < currentItem.position.end.x) {
              let startX = currentItem.position.end.x;
              let x = startX + margin;
              fenceItem.position.start.x = startX;
              fenceItem.position.end.x = x;
              fenceItem.position.start.y = currentItem.position.start.y;
              fenceItem.position.end.y = currentItem.position.end.y;
              this.fenceItems.push(fenceItem);
              this.selectFenceItem(fenceItem);
            }
          }
        }
      }
    }
  }

  removeCurrentItem(): void {
    if (this.fenceItems.length > 1) {
      const index = this.fenceItems.findIndex(value => value.id === this.selectedFenceItemIndex);
      if (this.getNextFenceItemToRight() != null && this.getNextFenceItemToLeft() == null) {
        const fenceItemToRight = this.getNextFenceItemToRight();
        fenceItemToRight.leftFenceItem = null;
        fenceItemToRight.field.leftPost = this.getPost(fenceItemToRight.field.series, fenceItemToRight.field.fenceHeight, FencePostType.end, this.coverColor);
        if (fenceItemToRight.field.leftPostCover != null) {
          fenceItemToRight.field.leftPostCover = this.getPostCover(fenceItemToRight.field.series, this.coverType, FencePostType.end, this.coverColor);
        }
        this.selectFenceItem(fenceItemToRight);
        this.fenceItems.splice(index, 1);
      } else if (this.getNextFenceItemToRight() == null && this.getNextFenceItemToLeft() != null) {
        let fenceItemToLeft = this.getNextFenceItemToLeft()
        fenceItemToLeft.rightFenceItem = null;
        fenceItemToLeft.field.rightPost = this.getPost(fenceItemToLeft.field.series, fenceItemToLeft.field.fenceHeight, FencePostType.end, this.coverColor);
        if (fenceItemToLeft.field.rightPostCover != null) {
          fenceItemToLeft.field.rightPostCover = this.getPostCover(fenceItemToLeft.field.series, this.coverType, FencePostType.end, this.coverColor);
        }
        this.selectFenceItem(fenceItemToLeft);
        this.fenceItems.splice(index, 1);
      }
    }
  }

  showLeftPost(item: FenceItemModel): boolean {
    if (item != null && item.type === FenceItemType.FenceField) {
      let leftItem = this.getNextFenceItemToLeftFrom(item);
      if (leftItem != null) {
        if (leftItem.type === FenceItemType.FenceField) {
          let leftItemRightPostHeight = leftItem.field.totalFenceHeight;
          if (leftItemRightPostHeight >= item.field.totalFenceHeight && (item.id !== this.selectedFenceItemIndex || this.getAngle(leftItem) === this.getAngle(item))) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  showRightPost(item: FenceItemModel): boolean {
    if (item != null && item.type === FenceItemType.FenceField) {
      let rightItem = this.getNextFenceItemToRightFrom(item);
      if (rightItem != null) {
        if (rightItem.type === FenceItemType.FenceField) {
          if (rightItem.id === this.selectedFenceItemIndex && this.getAngle(rightItem) !== this.getAngle(item)) {
            return false;
          }
          let rightItemRightPostHeight = rightItem.field.totalFenceHeight;
          if (rightItemRightPostHeight > item.field.totalFenceHeight) {
            return false;
          } else {
            return true;
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  saveItems(items): void {
    this.$store.commit('saveItems', items);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  callSaveItems: Function;

  //FIXME Remove Watcher
  @Watch('fenceItems', {immediate: true, deep: true})
  onFenceItemsChange(newVal: Array<FenceItemModel>): void {
    this.itemsChanged = true;
    if (this.initialized === true) {
      this.callSaveItems(newVal);
      this.callPriceCalculation();
    }
  }

  //FIXME Remove Watcher
  @Watch('selectedFenceItemIndex', {immediate: true})
  onSelectedFenceItemChange(newVal: number): void {
    if (this.initialized === true) {
      this.$store.commit('updateSelectedItemIndex', newVal);
      this.viewPort.targetRotate = this.getAngle(this.fenceItems.find(value => value.id === newVal));
    }
  }

  @Watch('viewPort', {immediate: true, deep: true})
  onViewPortChange(newVal: ViewPort): void {
    if (this.initialized === true) {
      if (newVal.x !== this.lastPosX || newVal.y !== this.lastPosY) {
        this.lastPositionUpdate = performance.now();
      }
    }
  }

  getAngle(item: FenceItemModel): number {
    return Math.atan2(item.position.start.y - item.position.end.y, item.position.start.x - item.position.end.x) * 180 / Math.PI + 180;
  }

  lerp(start: number, end: number, amt: number): number {
    return end;
  }

  lerpDegrees(start: number, end: number, amt: number): number {
    return end;
  }

  running = false;
  lastPositionUpdate = performance.now();

  animationFrame(): void {
    if (this.running === true) {
      this.viewPort.renderedX = this.lerp(this.viewPort.renderedX, this.viewPort.x, 0.125);
      this.viewPort.renderedY = this.lerp(this.viewPort.renderedY, this.viewPort.y, 0.125);
      this.viewPort.vXRotationAdjustment = this.lerp(this.viewPort.vXRotationAdjustment, this.vXRotationAdjustment, 0.125);
      this.viewPort.vYRotationAdjustment = this.lerp(this.viewPort.vYRotationAdjustment, this.vYRotationAdjustment, 0.125);
      this.viewPort.rotate = this.lerpDegrees(this.viewPort.rotate, this.viewPort.targetRotate, 0.175);
      setTimeout(this.animationFrame, 200);
    }
  }


  onKeyPress(event): void {
    if (event.altKey) {
      event.preventDefault();
      if (event.code === "ArrowLeft") {
        this.viewPort.x -= this.vXRotationAdjustment / 2;
        this.viewPort.y -= this.vYRotationAdjustment / 2;
      } else if (event.code === "ArrowRight") {
        this.viewPort.x += this.vXRotationAdjustment / 2;
        this.viewPort.y += this.vYRotationAdjustment / 2;
      } else if (event.code === "ArrowUp") {
        this.viewPort.x += this.vYRotationAdjustment / 2;
        this.viewPort.y -= this.vXRotationAdjustment / 2;
      } else if (event.code === "ArrowDown") {
        this.viewPort.x -= this.vYRotationAdjustment / 2;
        this.viewPort.y += this.vXRotationAdjustment / 2;
      }
    } else if (event.shiftKey) {
      if (event.code === "ArrowLeft") {
        this.viewPort.targetRotate -= 5;
      } else if (event.code === "ArrowRight") {
        this.viewPort.targetRotate += 5;
      }
    } else {
      if (event.code === "ArrowLeft") {
        this.callMoveLeft();
      } else if (event.code === "ArrowRight") {
        this.callMoveRight();
      }
    }
  }

  toggleSidebar(): void {
    this.sidebarDrawerOpen = !this.sidebarDrawerOpen;
  }

  selectFenceItemById(id: number): void {
    let item = this.fenceItems.find(value => value.id === id);
    if (item != null) {
      this.selectFenceItem(item);
    }
  }

  copyLeftFenceItem(item: FenceItemModel): void {
    this.addFenceItemToLeft(item);
  }

  copyRightFenceItem(item: FenceItemModel): void {
    this.addFenceItemToRight(item);
  }

  getPost(series: string, height: number, postType: FencePostType, color: FenceCoverColor) {
    return Configurator.getPostStatic(series, height, postType, color);
  }

  static getPostStatic(series: string, height: number, postType: FencePostType, color: FenceCoverColor): FencePost | null {
    let products = ProductHelper.getPost(series, height, postType, color);
    if (products.length > 0) {
      let product = products[0];
      return {
        productNumber: product.productNumber,
        height: product.height,
        type: postType,
        color: color
      }
    }
    return null;
  }

  getPostCover(series: string, coverType: FenceCoverType, postType: FencePostType, color: FenceCoverColor): FencePostCover | null {
    let products = ProductHelper.getPostCoverOptions(series, coverType, postType, color);
    if(products.length > 0) {
      let product = products[0];
      return {
        productNumber: product.productNumber,
        color: product.color,
        height: product.height,
        type: coverType,
        postType: postType
      } as FencePostCover;
    }
    return null;
  }

  getPlateCover(series: string, coverType: FenceCoverType, color: FenceCoverColor): FencePlateCover | null {
    const products = ProductHelper.getPlateCoverOptions(series, coverType, color);
    if (products.length > 0) {
      const product = products[0];
      return {
        productNumber: product.productNumber,
        color: product.color,
        height: product.height,
        type: product.coverType
      } as FencePlateCover;
    }
    return null;
  }

  onOpenCoverSettings() {
    this.fenceCoverDialogOpened = true;
  }

  created(): void {
    EventBus.$on('popover-cover-settings-modal', this.onOpenCoverSettings);
  }

  destroyed(): void {
    EventBus.$off('popover-settings-modal', this.onOpenCoverSettings);
  }

  onCoverDialogChangeStep(step: number) {
    this.coverDialogStep = step;
  }

  onSelectCover(args: {coverType: FenceCoverType, color: FenceCoverColor}): void {
    this.coverType = args.coverType;
    this.coverColor = args.color;

    this.$store.commit("updateCoverType", this.coverType);
    this.$store.commit("updateCoverColor", this.coverColor);

    this.fenceItems.forEach((item) => {
      if (item.field != null && item.type !== FenceItemType.FenceDoor) {
        
        const leftPost = this.getPost(item.field.series, item.field.fenceHeight, item.field.leftPost.type, this.coverColor);
        if (leftPost != null) {
          item.field.leftPost = leftPost;
          if (item.leftFenceItem?.field != null && item.leftFenceItem?.type !== FenceItemType.FenceDoor) {
            item.leftFenceItem.field.rightPost = leftPost;
          }
        } else {
          console.error("onSelectCover: ", "leftPost is null!", "item", item, "leftPostType", item.field.leftPost.type, "coverColor", this.coverColor);
        }

        const rightPost = this.getPost(item.field.series, item.field.fenceHeight, item.field.rightPost.type, this.coverColor);
        if (rightPost != null) {
          item.field.rightPost = rightPost;
          if (item.rightFenceItem?.field != null && item.rightFenceItem?.type !== FenceItemType.FenceDoor) {
            item.rightFenceItem.field.leftPost = rightPost;
          }
        } else {
          console.error("onSelectCover: ", "rightPost is null!", "item", item, "rightPostType", item.field.rightPost.type, "coverColor", this.coverColor);
        }

        if (this.coverType !== FenceCoverType.none) {

          let plateCover = this.getPlateCover(item.field.series, this.coverType, this.coverColor);
          if (plateCover != null) {
            item.field.fencePlateCover = plateCover;
          }

          const leftPostCover = this.getPostCover(item.field.series, this.coverType, item.field.leftPost.type, this.coverColor);
          if (leftPostCover != null) {
            item.field.leftPostCover = leftPostCover;
            if (item.leftFenceItem?.field != null) {
              item.leftFenceItem.field.rightPostCover = leftPostCover;
            }
          } else {
            console.error("onSelectCover: ", "leftPostCover is null!", "item", item, "coverType", args.coverType, "color", this.coverColor);
          }          

          const rightPostCover = this.getPostCover(item.field.series, this.coverType, item.field.rightPost.type, this.coverColor);
          if (rightPostCover != null) {
            item.field.rightPostCover = rightPostCover;
            if (item.rightFenceItem?.field != null && item.rightFenceItem?.type !== FenceItemType.FenceDoor) {
              item.rightFenceItem.field.leftPostCover = rightPostCover;
            }
          } else {
            console.error("onSelectCover: ", "rightPostCover is null!", "item", item, "coverType", args.coverType, "color", this.coverColor);
          }
        } else {
          item.field.leftPostCover = null;
          item.field.rightPostCover = null;
          item.field.fencePlateCover = null;
        }
      }
    });
    this.fenceCoverDialogOpened = false;

  }

  PostHelper(type:number): FencePostType
  {
    if(type === 0) {
      return FencePostType.corner
    }else if(type === 1){
      return FencePostType.intermediate
    }else if(type === 2){
      return FencePostType.end
    }
  }

  onPlateHeightChange(value:string): void {
    const height = parseInt(value);
    let plates: Array<FencePlate>,prodNumber : string;
    for(const el of this.fenceItems) {
      if (!el.door) {
        plates = [];
        el.field.plateHeight = height;
        if(height === 26)
        {
          if(ProductHelper.getPostProdNumber(208, this.PostHelper(el.field.leftPost.type), el.field.leftPost.color)) {
            el.field.leftPost.height = height
            el.field.leftPost.productNumber = ProductHelper.getPostProdNumber(208, this.PostHelper(el.field.leftPost.type), el.field.leftPost.color)
          } else {
            console.debug('no left Post match')
          }
          if(ProductHelper.getPostProdNumber(208, this.PostHelper(el.field.rightPost.type), el.field.rightPost.color)){
            el.field.rightPost.height = height
            el.field.rightPost.productNumber = ProductHelper.getPostProdNumber(208, this.PostHelper(el.field.rightPost.type), el.field.rightPost.color)
          }else {
            console.debug('no right Post match')
          }
          //FIXME: fixed height
          el.field.fenceHeight = 208;

          //FIXME: Connection via label valid?
          const defaultPlate = ProductHelper.getProduct(el.field.plates[0].productNumber)
          if(ProductHelper.getPlateProdNumber(defaultPlate.label,height,defaultPlate.series) != null){
            prodNumber = ProductHelper.getPlateProdNumber(defaultPlate.label,height,defaultPlate.series)
          }else{
            //console.debug('no matching Plate with that height Found');
            prodNumber = "03006K"; //Fels Anthrazit
          }
          const plate = {
            productNumber: prodNumber,
            settingsOpen: false
          }
          for (let i = 0; i<8;i++) {
            plates.push(plate)
          }
          el.field.plates = plates;
          //TODO: set total fence height?
          //el.field.totalFenceHeight =
        }else if( height === 36 )
        {
          if(ProductHelper.getPostProdNumber(252, this.PostHelper(el.field.leftPost.type), el.field.leftPost.color)) {
            el.field.leftPost.height = height
            el.field.leftPost.productNumber = ProductHelper.getPostProdNumber(252, this.PostHelper(el.field.leftPost.type), el.field.leftPost.color)
          } else {
            console.debug('no left Post match')
          }
          if(ProductHelper.getPostProdNumber(252, this.PostHelper(el.field.rightPost.type), el.field.rightPost.color)){
            el.field.rightPost.height = height
            el.field.rightPost.productNumber = ProductHelper.getPostProdNumber(252, this.PostHelper(el.field.rightPost.type), el.field.rightPost.color)
          }else {
            console.debug('no right Post match')
          }
          //FIXME: fixed height
          el.field.fenceHeight = 252;

          //FIXME: Connection via label valid?
          const defaultPlate = ProductHelper.getProduct(el.field.plates[0].productNumber)
          if(ProductHelper.getPlateProdNumber(defaultPlate.label,height,defaultPlate.series) != null){
            prodNumber = ProductHelper.getPlateProdNumber(defaultPlate.label,height,defaultPlate.series)
          }else{
            //console.debug('no matching Plate with that height Found');
            prodNumber = "03034K"; //Fels Anthrazit
          }
          const plate = {
            productNumber: prodNumber,
            settingsOpen: false
          }
          for (let i = 0; i<7;i++) {
            plates.push(plate)
          }
          el.field.plates = plates;
          //TODO: set total fence height?
          //el.field.totalFenceHeight = ?
        }
      }
    }
  }

  mounted(): void {
    ProductHelper.initialize().then(() => {
      console.debug("Done loading products");
    })
    this.callMoveRight = this._.debounce(this.moveRight, 50, {maxWait: 150});
    this.callMoveLeft = this._.debounce(this.moveLeft, 50, {maxWait: 150});
    this.callSaveItems = this._.debounce(this.saveItems, 200, {maxWait: 250});
    EventBus.$on('select-fence-item', this.selectFenceItemById);
    EventBus.$on('configurator-copy-right', this.copyRightFenceItem);
    EventBus.$on('configurator-copy-left', this.copyLeftFenceItem);

    document.addEventListener('keydown', this.onKeyPress);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.viewPort.size.width = this.$refs.editor.clientWidth;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.viewPort.size.height = this.$refs.editor.clientHeight;
    const waitForStorage = async () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      await this.$store.restored;
      this.selectedFenceItemIndex = this.$store.state['Configuration'].selectedItemIndex;
      this.fenceItems = [];
      this.fenceItems.push(...this.$store.state['Configuration'].fenceItems);
      if (this.fenceItems.length === 0) {
        this.fenceItems.push(this.createDefaultFenceItem());
      } else {
        this.fenceItems.forEach(fenceItem => {
          if (fenceItem.type === FenceItemType.FenceField) {
            fenceItem.field.plates.forEach(plate => {
              plate.settingsOpen = false;
            });
          }
        });
      }

      this.coverType = this.$store.state['Configuration'].fenceCoverType;
      this.coverColor = this.$store.state['Configuration'].fenceCoverColor;

      if(this.coverType == null || this.coverColor == null) {
        this.coverType = FenceCoverType.pyramid;
        this.coverColor = FenceCoverColor.anthrazit;
        //this.fenceCoverDialogOpened = true;
      }

      let selectedItem = this.fenceItems.find(value => value.id === this.selectedFenceItemIndex);
      if (selectedItem != null) {
        this.viewPort.x = -selectedItem.position.start.x;
        //take out to store, too?
        this.currentPlateHeight = selectedItem.field?.plateHeight;
      } else {
        this.selectedFenceItemIndex = 0;
      }
      this.initialized = true;
      this.buildCachedFenceItems();
      this.running = true;
      this.selectFenceItem(this.fenceItems.find(value => value.id === this.selectedFenceItemIndex));
      this.animationFrame();
    }

    waitForStorage();

    this.cachedFenceItemRebuildLoopId = setInterval(() => {
      queueMicrotask(this.buildCachedFenceItems);
    }, 350);
  }

  unmounted(): void {
    EventBus.$off('select-fence-item', this.selectFenceItemById);
    EventBus.$off('configurator-copy-right', this.copyRightFenceItem);
    EventBus.$off('configurator-copy-left', this.copyLeftFenceItem);
    document.removeEventListener('keydown', this.onKeyPress);
    clearInterval(this.cachedFenceItemRebuildLoopId);
    this.running = false;
  }

  selectedFenceType = {
    label: 'Holz Beton',
    value: 'holz_beton',
  }

  fenceTypes: Array<InputDropdownOption> = [
    {
      label: "Holz Beton",
      value: "holz_beton",
      selected: true
    },
    {
      label: "Premium Plus",
      value: "premium_plus",
      selected: false
    }
  ];

  onSelectFenceType(selected: { label: string; value: string; }): void {
    this.selectedFenceType.label = selected.label;
    this.selectedFenceType.value = selected.value;
  }

  createDefaultFenceItem(isDoor = false): FenceItemModel {
    const leftPostCover = isDoor ? null : this.getPostCover("premium_plus", this.coverType, FencePostType.end, this.coverColor);
    const rightPostCover = isDoor ? null : this.getPostCover("premium_plus", this.coverType, FencePostType.end, this.coverColor);
    const fencePlateCover = isDoor ? null : this.getPlateCover("premium_plus", this.coverType, this.coverColor);
    return {
      id: Number.parseInt(Math.round((this.fenceItems.length * 16231244921) * Math.random()).toFixed(0)),
      type: isDoor ? FenceItemType.FenceDoor : FenceItemType.FenceField,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      field: {
        series: 'premium_plus',
        platePattern: FencePlatePattern.bilateral,
        fenceHeight: 252,
        plateHeight: 36,
        leftPost: isDoor ? null : this.getPost("premium_plus", 252, FencePostType.end, this.coverColor),
        rightPost: isDoor ? null : this.getPost("premium_plus", 252, FencePostType.end, this.coverColor),
        leftPostCover: leftPostCover,
        rightPostCover: rightPostCover,
        fencePlateCover: fencePlateCover,
        plates: [
          {
            productNumber: "03034K",
            settingsOpen: false
          },
          {
            productNumber: "03034K",
            settingsOpen: false
          },
          {
            productNumber: "03034K",
            settingsOpen: false
          },
          {
            productNumber: "03034K",
            settingsOpen: false
          },
          {
            productNumber: "03034K",
            settingsOpen: false
          },
          {
            productNumber: "03034K",
            settingsOpen: false
          },
          {
            productNumber: "03034K",
            settingsOpen: false
          },
        ],
        totalFenceHeight:256.5,
      },
      door: null,
      position: {
        start: {
          x: 0,
          y: 0
        },
        end: {
          x: this.fenceItemWidth,
          y: 0
        }
      }
    };
  }

  calculationId = -1;
  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  purchase(): void {
    let productNumbers = Object.getOwnPropertyNames(this.cart);

  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  callPriceCalculationDebounced: Function = null;
  callPriceCalculation(): void {
    if(this.callPriceCalculationDebounced == null) {
      this.callPriceCalculationDebounced = this._.debounce(this.calculatePrices, 250);
    }
    this.loadingPrices = true;
    this.callPriceCalculationDebounced();
  }

  async calculatePrices(): Promise<void> {
    let id = Math.floor(Math.random()*(128*performance.now()));
    this.calculationId = id;
    while (ProductHelper.fetched === false) {
      await this.timeout(100);
    }
    let prices: PriceModel = {
      fenceLength: 0,
      postCount: 0,
      plateCount: 0,
      postCoverCount: 0,
      roofCapsCount: 0,
      deliverConcrete: this.prices.deliverConcrete,
      deliverColor: this.prices.deliverColor,
      pricePlates: 0,
      pricePosts: 0,
      priceCoverAndOther: 0,
      priceConcreteAndColor: 0
    }
    prices.fenceLength = this.fenceItems.length;
    this.fenceItems.forEach(item => {
      this.getNextFenceItemToLeftFrom(item);
      this.getNextFenceItemToRightFrom(item);
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$worker.run((prices: PriceModel, fenceItems: Array<FenceItemModel>, products: Array<ProductModel>) => {
      let cart = {};
      prices.fenceLength = fenceItems.length;
      fenceItems.forEach(item => {
        // a door
        if (item.type === 1) { 
          prices.priceCoverAndOther += Number.parseFloat(products.find(prod => item.door.productNumber === prod.productNumber).price);
          cart[item.door.productNumber] = (cart[item.door.productNumber] || 0) + 1;
          return true;
        }

        // normal fence
        let plateCount = item.field.fenceHeight/item.field.plateHeight;
        prices.plateCount += plateCount;
        let splicedPlates = item.field.plates.splice(0, plateCount);
        splicedPlates.forEach(value => {
          prices.pricePlates += Number.parseFloat(products.find(prod => prod.productNumber === value.productNumber).price);
          cart[value.productNumber] = (cart[value.productNumber] || 0) + 1;
        });

        let showLeftPost = item.leftFenceItem == null || item.leftFenceItem.type === 1 || item.leftFenceItem.field.totalFenceHeight < item.field.totalFenceHeight;
        let showRightPost = item.rightFenceItem == null || item.rightFenceItem.type === 1 || item.rightFenceItem.field.totalFenceHeight <= item.field.totalFenceHeight;

        // left post
        if (showLeftPost) {
          prices.postCount+=1;
          if(item.field.leftPostCover != null) {
            prices.postCoverCount++;
            prices.priceCoverAndOther += Number.parseFloat(products.find(prod => item.field.leftPostCover.productNumber === prod.productNumber).price);
            cart[item.field.leftPostCover.productNumber] = (cart[item.field.leftPostCover.productNumber] || 0) + 1;
          }

          prices.pricePosts += Number.parseFloat(products.find(prod => item.field.leftPost.productNumber === prod.productNumber).price);
          cart[item.field.leftPost.productNumber] = (cart[item.field.leftPost.productNumber] || 0) + 1;
        }

        // right post
        if (showRightPost) {
          prices.postCount+=1;
          if(item.field.rightPostCover != null) {
            prices.postCoverCount++;
            prices.priceCoverAndOther += Number.parseFloat(products.find(prod => item.field.rightPostCover.productNumber === prod.productNumber).price);
            cart[item.field.rightPostCover.productNumber] = (cart[item.field.rightPostCover.productNumber] || 0) + 1;
          }

          prices.pricePosts += Number.parseFloat(products.find(prod => item.field.rightPost.productNumber === prod.productNumber).price);
          cart[item.field.rightPost.productNumber] = (cart[item.field.rightPost.productNumber] || 0) + 1;
        }

        // fence plate cover
        if(item.field.fencePlateCover != null) {
          prices.roofCapsCount += 1;
          prices.priceCoverAndOther += Number.parseFloat(products.find(prod => item.field.fencePlateCover.productNumber === prod.productNumber).price);
          cart[item.field.fencePlateCover.productNumber] = (cart[item.field.fencePlateCover.productNumber] || 0) + 1;
        }
      });

      return [prices, cart];
    }, [prices, this.fenceItems, ProductHelper.productsMap]).then((result) => {
      if(this.calculationId === id) {
        this.loadingPrices = false;
        this.prices = result[0]; 
        this.cart = {};
        this.cart = result[1];
      }
    });
  }

}
