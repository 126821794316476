
import {Component, Prop, Vue} from 'vue-property-decorator';
import {FenceCoverColor, FenceCoverType} from "../../../types/editor";


@Component({})
export default class FenceCoverDialog extends Vue {

  @Prop({ 
    default: FenceCoverType.pyramid
  })
  selectedCoverType: FenceCoverType;

  @Prop({ 
    default: FenceCoverColor.anthrazit
  })
  selectedCoverColor: FenceCoverColor;

  dialogStage = 0;

  coverType: FenceCoverType;
  coverColor: FenceCoverColor;

  created() {
    this.coverType = this.selectedCoverType;
    this.coverColor = this.selectedCoverColor;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  colorTextures(color: string) {
    const paths = {
      naturgrau: `/textures/cover-dialog/${FenceCoverType[this.coverType] === 'none'?'flat':FenceCoverType[this.coverType]}-cut-naturgrau.jpg`,
      anthrazit: `/textures/cover-dialog/${FenceCoverType[this.coverType] === 'none'?'flat':FenceCoverType[this.coverType]}-cut-anthrazit.jpg`
    };
    return paths[color];
  }

  selectCover(coverType: number) {
    if (coverType === 0) {
      this.coverType = FenceCoverType.flat;
      this.dialogStage = 1;
      this.$emit('change-step', 1);
    } else if(coverType === 1) {
      this.coverType = FenceCoverType.pyramid;
      this.dialogStage = 1;
      this.$emit('change-step', 1);
    } else if (coverType === 2) {
      this.coverType = FenceCoverType.none;
      //this.$forceUpdate();
      this.dialogStage = 1;
      this.$emit('change-step', 1);
      //this.$emit('select-cover', {coverType: this.coverType, color: this.coverColor});
    }
  }

  selectColor(color: number): void {
    if (color === 0) {
      this.coverColor = FenceCoverColor.naturgrau;
    } else if (color === 1) {
      this.coverColor = FenceCoverColor.anthrazit;
    }
    this.dialogStage = 0;
    this.$emit('select-cover', {coverType: this.coverType, color: this.coverColor});
    this.$emit('change-step', 0);
  }
}
