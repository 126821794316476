import {Module} from "vuex";
import {VuexModule} from "@/store/modules/modules";
import {FenceCoverColor, FenceCoverType, FenceItemModel} from "../../../types/editor";
import { setTextRange } from "typescript";

export interface ConfigurationModel {
    id: number;
    name: string;
    fenceItems: Array<FenceItemModel>;
    saveStamp: number;
    selectedItemIndex: number;
    fenceCoverType: FenceCoverType;
    fenceCoverColor: FenceCoverColor;
}

export interface ConfigurationState extends VuexModule {
    fenceItems: Array<FenceItemModel>;
    selectedItemIndex: number;
    configurations: Array<ConfigurationModel>;
    currentSelectedConfiguration: number;
    lastSaveStamp: number;
    fenceCoverType?: FenceCoverType;
    fenceCoverColor?: FenceCoverColor;
}

export const state: ConfigurationState = {
    name: 'ConfigurationState',
    fenceItems: [],
    selectedItemIndex: 0,
    configurations: [],
    currentSelectedConfiguration: -1,
    lastSaveStamp: 0,
    fenceCoverType: null,
    fenceCoverColor: null
}

export const Configuration: Module<ConfigurationState, any> = {
    state,
    mutations: {
        saveItems(state, items) {
          state.fenceItems = items;
          state.lastSaveStamp = Math.random() * performance.now() * performance.now();
        },
        updateCoverType(state, coverType: FenceCoverType) {
          state.fenceCoverType = coverType;
          state.lastSaveStamp = Math.random() * performance.now() * performance.now();
        },
        updateCoverColor(state, coverColor: FenceCoverColor) {
          state.fenceCoverColor = coverColor;
          state.lastSaveStamp = Math.random() * performance.now() * performance.now();
        },
        updateSelectedItemIndex(state, item) {
          state.selectedItemIndex = item;
        },
        updateCurrentConfigurationId(state, id) {
          state.currentSelectedConfiguration = id;
        },
        updateLastSaveStamp(state, stamp) {
          state.lastSaveStamp = stamp;
        },
        saveConfiguration(state, configuration) {
          configuration.saveStamp = Math.random() * performance.now() * performance.now();
          state.lastSaveStamp = configuration.saveStamp;
          state.currentSelectedConfiguration = configuration.id;

          const existingConfigIndex = state.configurations.findIndex(value => value.id === configuration.id);
          if (existingConfigIndex !== -1) {
            state.configurations[existingConfigIndex] = configuration;
          } else {
            state.configurations.push(configuration);
          }
        }
    },
    actions: {
    },
    getters: {
    }
}
