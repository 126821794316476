
import 'vue-class-component'
import {Component, Prop, Model, Emit, Vue, Watch} from 'vue-property-decorator';

@Component
export default class extends Vue {
  @Prop({
    default: 35,
  })
  height: number;
  @Prop({
    default: 30
  })
  maxLength: number;
}
