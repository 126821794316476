
import {Component, Prop, Model, Emit, Vue, Watch} from 'vue-property-decorator';

export interface InputDropdownOption {
  label: string;
  value: any;
  selected: boolean;
}

@Component
export default class InputDropdown extends Vue {

  @Prop({
    required: true
  })
  readonly label: string;

  @Prop({
    default: false
  })
  readonly highlightSelected: boolean;

  @Prop()
  readonly options: Array<InputDropdownOption>;

  get activeLabel(): string | undefined {
    return this.options.some(value => value.selected) ? this.options.find(value => value.selected).label : this.label;
  }

  get activeValue(): string | undefined {
    return (this != null && this.options != null && this.options.some(value => value.selected)) ? this.options.find(value => value.selected).value : 'none';
  }

  active = false;

  @Emit('change')
  select(option: InputDropdownOption, e: Event): {label: string | undefined; value: string | undefined;} | undefined {
    e.stopPropagation();
    this.options.forEach(value => value.value != option.value ? value.selected = false : value.selected = true);
    this.disable(null);
    return {label: this.activeLabel, value: this.activeValue};
  }

  enable(): void {
    if(!this.active) {
      this.active = true;
    }
  }

  disable(e: Event | null): void {
    if(e != null) {
      e.stopPropagation();
    }
    if(this.active) {
      this.active = false;
    }
  }
}
