
import 'vue-class-component'
import {Component, Prop, Model, Emit, Vue, Watch} from 'vue-property-decorator';

@Component
export default class IconClose extends Vue {
  @Prop({
    default: 20
  })
  width: number;
}
