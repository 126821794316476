
import 'vue-class-component'
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import {EventBus} from '@/plugins/event-bus';
import InputSwitch from "@/components/inputs/InputSwitch.vue";
import InputDropdown, {InputDropdownOption} from "@/components/inputs/InputDropdown.vue";

import {
  FenceCoverType,
  FenceCoverColor,
  FenceItemModel,
  FencePlate, FencePlateCover,
  FencePlatePattern,
  FencePost,
  FencePostType,
  ProductHelper,
  Properties
} from "../../../types/editor";
import TexturesHelper from "@/util/textures";
import Configurator from '../Configurator.vue';

export interface DropdownActionItemOption {
  name: string;
  value: any;
}

export enum ActionItemType {
  switch,
  variant,
  dropdown
}

export interface SwitchActionItem extends ActionItem {
  type: ActionItemType.switch;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
  enabled: boolean;
}

export interface DropdownActionItem extends ActionItem {
  type: ActionItemType.dropdown;
  options: Array<InputDropdownOption>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onChange: Function;
}

export interface VariantSelectActionItem extends ActionItem {
  // eslint-disable-next-line @typescript-eslint/ban-types
  onSelect: Function;
  type: ActionItemType.variant;
  thumbnailUrl: string;
  price: string;
  productNumber: string;
}

export interface ActionItem {
  name: string;
  type: ActionItemType;
}

export interface Action {
  label: string;
  items: Array<ActionItem>;
}

export interface PlateOption {
  thumbnailUrl: string;
  name: string;
  price: string;
  productNumber: string;
}

export interface PostOption {
  thumbnailUrl: string;
  name: string;
  price: string;
  productNumber: string;
  cover: FencePostType;
}

export interface PlateCoverOption {
  thumbnailUrl: string;
  name: string;
  price: string;
  productNumber: string;
}

export enum DialogIndex {
  PLATE = 0, POST = 1
}

@Component({
  components: {InputDropdown, InputSwitch}
})
export default class PopoverSettingsModal extends Vue {
  dropdownKey = 0;

  index = 0;

  dialogIndex: DialogIndex = 0;

  plate: FencePlate;

  post: FencePost;

  plateCover: FencePlateCover;

  plateBilateral = true;

  fenceHeight = 182;

  applyToAllPlates = true;

  $refs!: {
    popover: HTMLDivElement
  }
  @Prop({
  })
  currentSelectedFenceItem: FenceItemModel;

  activated = false;

  currentMousePosition = {
    x: 0,
    y: 0
  }

  position = {
    x: 0,
    y: 0
  }

  selectPlateOption(productNumber: string): void {
    if(this.applyToAllPlates) {
      this.currentSelectedFenceItem.field.plates.forEach(value => value.productNumber = productNumber);
    }else {
      this.currentSelectedFenceItem.field.plates[this.index].productNumber = productNumber;
    }
    this.disable();
  }

  getImg(number: string): string {
    let product = ProductHelper.getProduct(number);
    if(product !== undefined) {
      return TexturesHelper.getTexturePath(product.file, product.series, product.type);
    }else {
      return '';
    }
  }

  onFenceHeightChange(val: number):void {
    this.fenceHeight = val;
    const field = this.currentSelectedFenceItem.field;
    
    field.fenceHeight = this.fenceHeight;

    if (field.leftPost) {
      field.leftPost = Configurator.getPostStatic(field.series, field.fenceHeight, FencePostType.end, field.leftPost.color);
    }

    if (field.rightPost) {
      field.rightPost = Configurator.getPostStatic(field.series, field.fenceHeight, FencePostType.end, field.rightPost.color);
    }
  }

  get plateHeightOptions(): Array<number> {
    return Properties.getHeightOptions(this.currentSelectedFenceItem?.field?.plateHeight)?Properties.getHeightOptions(this.currentSelectedFenceItem?.field?.plateHeight).sort((a,b)=>{return a-b}):[];
    // return this.currentSelectedFenceItem?.field?.plateHeight === 26 ? [
    //   182,
    //   208,
    //   234
    // ] : this.currentSelectedFenceItem?.field?.plateHeight === 36 ? [
    //   180,
    //   216,
    //   252
    // ] : [];
  }


  get plateOptions(): Array<PlateOption> {
    if(this.currentSelectedFenceItem == null) {
      return [];
    }
    let products = ProductHelper.getPlateOptions(this.currentSelectedFenceItem.field.series, this.currentSelectedFenceItem.field.plateHeight, this.plateBilateral ? FencePlatePattern.bilateral : FencePlatePattern.unilateral);
    products = products.filter((value, index) => {
      return products.some((value1, index1) => value1.productNumber === value.productNumber && index1 !== index && index < index1) === false;
    });

    return products.map(value => {
      return {
        thumbnailUrl: this.getImg(value.productNumber),
        name: value.label,
        price: value.price,
        productNumber: value.productNumber
      }
    });
  }

  postOptions(): Array<PostOption> {
    if(this.currentSelectedFenceItem == null || this.post == null) {
      return [];
    }

    const products = ProductHelper.getPostOptions(this.currentSelectedFenceItem.field.series, this.currentSelectedFenceItem.field.fenceHeight, FencePostType[this.post.type]);

    return products.map(value => {
      return {
        thumbnailUrl: this.getImg(value.productNumber),
        name: value.label,
        price: value.price,
        productNumber: value.productNumber,
        cover: this.post.type
      }
    });
  }

  plateCoverOptions(): Array<PlateCoverOption> {
    if(this.currentSelectedFenceItem == null || this.plateCover == null) {
      return [];
    }
    const products = ProductHelper.getPlateCoverOptions(this.currentSelectedFenceItem.field.series, FenceCoverType[this.plateCover.type]);
    return products.map(value => {
      return {
        thumbnailUrl: this.getImg(value.productNumber),
        name: value.label,
        price: value.price,
        productNumber: value.productNumber,
      }
    });
  }

  onPopupSettingsModal(args): void {
    this.dialogIndex = 0;
    this.index = args.index;
    this.plate = args.plate;
    this.fenceHeight = this.currentSelectedFenceItem.field.fenceHeight;
    this.plateBilateral = this.currentSelectedFenceItem.field.platePattern === FencePlatePattern.bilateral;
    this.position.x = args.x;
    this.position.y = args.y;
    this.$nextTick(() => {
      this.activated = true;
    });
  }

  onPopSettingsModalClose(): void {
    this.disable();
  }

  created(): void {
    EventBus.$on('popover-settings-modal', this.onPopupSettingsModal);
    EventBus.$on('popover-settings-modal-close', this.onPopSettingsModalClose);
  }

  destroyed(): void {
    EventBus.$off('popover-settings-modal', this.onPopupSettingsModal);
    EventBus.$off('popover-settings-modal-close', this.onPopSettingsModalClose);
    this.disable();
  }

  onSelectAction(action: VariantSelectActionItem): void {
    if (action.onSelect !== undefined) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      action.onSelect(action, this.tag);
    }
    this.disable();
  }

  @Watch('plateBilateral', {immediate: true})
  onPlateBilateralChange(newVal: boolean):void {
    if(this.plate != null) {
      this.plate.pattern = newVal === true ? FencePlatePattern.bilateral : FencePlatePattern.unilateral;
      this.currentSelectedFenceItem.field.platePattern = this.plate.pattern;
    }
  }

  @Emit('change')
  toggle(): boolean {
    this.activated = !this.activated;
    return this.activated;
  }

  @Emit('change')
  enable(): boolean {
    this.activated = true;
    return true;
  }

  @Emit('change')
  disable(): boolean {
    this.activated = false;
    return false;
  }
}
