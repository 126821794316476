
import 'vue-class-component'
import {Component, Vue} from 'vue-property-decorator';
import {PopupInstance, PopupType} from "../../../types/editor";
import IconClose from "@/components/icons/IconClose.vue";
import IconCheck from "@/components/icons/IconCheck.vue";
import InputText from "@/components/inputs/InputText.vue";

@Component({
  components: {InputText, IconCheck, IconClose}
})
export default class PopupModal extends Vue {
  zBaseValue = 1000;
  popups: Array<PopupInstance> = [
    {
      title: 'Test',
      description: 'This is a testdddddddddddddddddwdwdwdd<br>dddddddddddddddddwdwdwdddddddddddddd<br>dddddddddddddddddddddddwdwdwdwdddddddddddddddddd!',
      id: Math.floor(Math.random()*230123),
      open: false,
      closable: true,
      type: PopupType.Info,
      actions: [

      ]
    },
    {
      title: 'Konfiguration Speichern',
      description: 'This is a test!',
      id: Math.floor(Math.random()*230123),
      open: false,
      closable: true,
      type: PopupType.Save,
      actions: [

      ]
    }
  ]

  get isSomePopupOpen(): boolean {
    return this.popups.some(value => value.open === true);
  }

  get highestZIndex(): number {
    return this.zBaseValue+(((this.popups.filter(value => value.open === true).length-1)*5)+1);
  }

  closePopup(instance: PopupInstance) {
    instance.open = false;
  }
}
