import Vue from 'vue'
import VueWorker from 'vue-worker'
import VueLodash from 'vue-lodash'
import lodash from 'lodash';
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify'
Vue.use(VueWorker, '$worker')
Vue.use(VueLodash, { lodash: lodash });
Vue.config.performance = true

Vue.config.productionTip = false
new Vue({
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
