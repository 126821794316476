
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  FenceCoverType,
  FenceItemModel,
  FenceItemType,
  FencePlate,
  FencePlateCover,
  FencePlatePattern,
  FencePost,
  FencePostCover, FencePostType,
  ProductHelper,
  ProductModel,
  Properties
} from "../../../types/editor";
import {EventBus} from '@/plugins/event-bus'
import TexturesHelper, {Texture} from "../../util/textures"
import {InputDropdownOption} from "@/components/inputs/InputDropdown.vue";
import PopoverSettings, {
  Action,
  ActionItemType,
  DropdownActionItem,
  SwitchActionItem,
  VariantSelectActionItem
} from "@/components/popovers/PopoverSettings.vue";
import Configurator from "@/components/Configurator.vue";

// eslint-disable-next-line @typescript-eslint/no-var-requires
//const hex = require('ral-to-hex');


@Component({
  components: {PopoverSettings}
})
export default class FenceItem extends Vue {
  @Prop({
    required: true
  })
  fenceItem: FenceItemModel | null;

  @Prop({
    default: true
  })
  showRightPost: boolean;

  @Prop({
    default: true
  })
  showLeftPost: boolean;

  @Prop({
    default: 360
  })
  angle: number;


  @Prop({
    default: false
  })
  debugging: boolean;

  @Prop({
    default: false
  })
  lastItemRight: boolean;

  @Prop({
    default: false
  })
  lastItemLeft: boolean;

  @Prop({
    default: false
  })
  showHeightCtrl: boolean;

  heightSettingsDialog = false;

  platePattern: FencePlatePattern = FencePlatePattern.none;

  leftPostSettingsModel = false;



  leftPostSettings: Array<Action> = [
    {
      label: "Left Post",
      items: []
    }
  ];

  rightPostSettingsModel = false;

  rightPostSettings: Array<Action> = [
    {
      label: "Right Post",
      items: []
    }
  ];

  topPostSettingsModel = false;

  topPostSettings: Array<Action> = [
    {
      label: "Front",
      items: []
    }
  ];

  bottomPostSettingsModel = false;

  get doorHeight(): number {
    return ProductHelper.getProduct(this.fenceItem.door?.productNumber ?? '')?.height ?? 0;
  }

  get doorHeightOptions(): Array<number> {
    return [
      180,
      200
    ];
  }

  plateSettingsModal = [];

  get sortedFenceHeightOptions(): Array<number> {
    return this.fenceHeightOptions.sort((a, b) => {
      return Math.abs(this.fenceItem.field.fenceHeight - a) - Math.abs(this.fenceItem.field.fenceHeight - b);
    });
  }

  get fenceHeightOptions(): Array<number> {
    return Properties.getHeightOptions(this.fenceItem?.field?.plateHeight)
  }

  get plateHeightOptions(): number {
    return 0
  }

  get plateSettings(): Array<Action> {
    return [
      {
        label: "Mauerabschnitt",
        items: [
          {
            type: ActionItemType.dropdown,
            name: "Zaunhöhe",
            onChange: (selected: {label: string; value: string;}): void => {
              this.fenceItem.field.fenceHeight = Number.parseInt(selected.value);
            },
            options: this.fenceHeightOptions.map((value, index) => {
              return {
                label: value + " cm",
                value: value,
                selected: value === this.fenceItem?.field?.fenceHeight
              }
            }) as Array<InputDropdownOption>,
          } as DropdownActionItem,
        ]
      },
      {
        label: "Plate",
        items: [
          {
            type: ActionItemType.switch,
            name: "Motiv beidseitig",
            enabled: this.platePattern === FencePlatePattern.bilateral,
            onChange: (e) => {
              this.platePattern = e === true ? FencePlatePattern.bilateral : FencePlatePattern.unilateral;
            }
          } as SwitchActionItem,
          {
            type: ActionItemType.switch,
            name: "Auf alle Platten anwenden",
            enabled: true,
            onChange: (e) => {
              console.log("auf alle Platten anwenden", e);
            }
          } as SwitchActionItem,
          ...this.plateOptions
        ]
      }
    ];
  }

  get plateOptions(): Array<VariantSelectActionItem> {
    let products = ProductHelper.getPlateOptions(this.fenceItem.field.series, this.fenceItem.field.plateHeight, this.platePattern);
    products = products.filter((value, index) => {
      return products.some((value1, index1) => value1.productNumber === value.productNumber && index1 !== index && index < index1) === false;
    });
    return products.map((value, index) => {
      return {
        thumbnailUrl: this.getImg(value.productNumber),
        name: value.label,
        price: value.price,
        productNumber: value.productNumber,
        onSelect: (action, tag) => {
          if(tag !== undefined && tag.includes('plate-')) {
            let index = Number.parseInt(tag.replace('plate-', ''));
            this.fenceItem.field.plates[index].productNumber = action.productNumber;
          }
        },
        type: ActionItemType.variant
      } as VariantSelectActionItem;
    });
  }

  generatePlateSettingsModalArray(): void {
    this.plateSettingsModal = [];
    for(let i = 0; i < this.fenceItem.field.plates.length; i++) {
      this.plateSettingsModal.push(false);
    }
  }

  get splicedPlates(): Array<FencePlate>  {
    return [...this.fenceItem?.field?.plates].splice(0, this.fenceItem?.field?.fenceHeight/this.fenceItem?.field?.plateHeight);
  }

  openPlateCoverSettingsDialog(plateCover: FencePlateCover): void {
    EventBus.$emit('popover-cover-settings-modal');
    EventBus.$emit('popover-settings-modal-close');
  }

  openPostSettingsDialog(post: FencePost, index: number): void {
    EventBus.$emit('popover-cover-settings-modal');
    EventBus.$emit('popover-settings-modal-close');
  }

  openSettingsDialog(plate: FencePlate, index: number): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    EventBus.$emit('popover-settings-modal', {plate: plate, index: index, x: event.clientX, y: event.clientY});
    this.heightSettingsDialog = false;
  }

  openHeightSettingsDialog(): void {
    this.heightSettingsDialog = true;
    EventBus.$emit('popover-settings-modal-close');
  }

  closeHeightSettingsDialog(option: number): void {
    this.heightSettingsDialog = false;
    if(this.fenceItem.type === FenceItemType.FenceField) {
      this.fenceItem.field.fenceHeight = option;
    }else if(this.fenceItem.type === FenceItemType.FenceDoor) {
      let doorProduct = ProductHelper.getDoor(option+0.0);
      if(doorProduct != null) {
        this.fenceItem.door.productNumber = doorProduct.productNumber;
        this.fenceItem.door.name = doorProduct.name;
        this.fenceItem.door.price = doorProduct.price;
      }
    }
  }
  //disable height on max height
  addHeight():void{
    const newHeight = this.fenceItem?.field?.fenceHeight + this.fenceItem?.field?.plateHeight;
    const field = this.fenceItem.field;

    field.fenceHeight = newHeight;

    if (field.leftPost) {
      field.leftPost = Configurator.getPostStatic(field.series, field.fenceHeight, FencePostType.end, field.leftPost.color);
    }

    if (field.rightPost) {
      field.rightPost = Configurator.getPostStatic(field.series, field.fenceHeight, FencePostType.end, field.rightPost.color);
    }
  }

  decreaseHeight():void{
    const newHeight = this.fenceItem?.field?.fenceHeight - this.fenceItem?.field?.plateHeight;
    const field = this.fenceItem.field;

    field.fenceHeight = newHeight;

    if (field.leftPost) {
      field.leftPost = Configurator.getPostStatic(field.series, field.fenceHeight, FencePostType.end, field.leftPost.color);
    }

    if (field.rightPost) {
      field.rightPost = Configurator.getPostStatic(field.series, field.fenceHeight, FencePostType.end, field.rightPost.color);
    }
  }

  created(): void {
    this.generatePlateSettingsModalArray();
  }

  mounted(): void {
    this.platePattern = this.fenceItem.field.platePattern;
    this.fenceItem.field.totalFenceHeight = this.calculateTotalFenceHeight;
    this.generatePlateSettingsModalArray();
    window["ProductHelper"] = ProductHelper;
  }

  // getHexFromRAL(ral: string): string {
  //   return hex(ral);
  // }

  getProduct(number: string): ProductModel {
    return ProductHelper.getProduct(number);
  }

  getTexturePathF(file: string, series: string, type: string): Texture {
    return TexturesHelper.getTexture(file, series, type);
  }

  getImg(number: string): string {
    let product = ProductHelper.getProduct(number);
    if(product !== undefined) {
      return TexturesHelper.getTexturePath(product.file, product.series, product.type);
    }else {
      return '';
    }
  }

  getDoorImage() {
    return TexturesHelper.getDoorTexturePath()
  }

  getLeftPostImg(): string {
    return this.getPostImg(this.fenceItem.field.leftPost.productNumber);
  }

  getRightPostImg(): string {
    return this.getPostImg(this.fenceItem.field.rightPost.productNumber);
  }

  getRightPostCoverImg(): string {
    return this.getCoverImg(this.fenceItem.field.rightPost.productNumber, this.fenceItem.field.rightPostCover);
  }

  getLeftPostCoverImg(): string {
    return this.getCoverImg(this.fenceItem.field.leftPost.productNumber, this.fenceItem.field.leftPostCover);
  }

  getCoverImg(postNumber: string, cover: FencePostCover) {
    const postProduct = ProductHelper.getProduct(postNumber);
    const coverProduct = cover ? ProductHelper.getProduct(cover.productNumber) : null;
    if (postProduct != null && coverProduct != null) {
      return TexturesHelper.getPostTexturePath(postProduct.series, postProduct.color, coverProduct.coverType);
    }
  }

  getPostImg(postNumber: string): string {
    let postProduct = ProductHelper.getProduct(postNumber);
    if (postProduct != null) {
      return TexturesHelper.getPostTexturePath(postProduct.series, postProduct.color, FenceCoverType[FenceCoverType.none]);
    }
    return '';
  }

  copyRight(): void {
    EventBus.$emit('configurator-copy-right', this.fenceItem);
  }

  copyLeft(): void {
    EventBus.$emit('configurator-copy-left', this.fenceItem);
  }

  getTexturePath(file: string, series: string, type: string): string {
    return TexturesHelper.getTexturePath(file, series, type);
  }

  get maxHeight(): boolean {
    return this.fenceItem != null && this.fenceItem.field.fenceHeight === this.fenceHeightOptions[this.fenceHeightOptions.length-1]
  }
  get minHeight(): boolean {
    return this.fenceItem != null && this.fenceItem.field.fenceHeight === this.fenceHeightOptions[0]
  }

  get isField(): boolean {
    return this.fenceItem != null && this.fenceItem.type === FenceItemType.FenceField;
  }

  get isDoor(): boolean {
    return this.fenceItem != null && this.fenceItem.door != null;
  }

  get calculateTotalFenceHeight(): number {
    return this.fenceItem?.field?.fenceHeight + (this.fenceItem?.field?.fencePlateCover != null ? this.fenceItem?.field?.fencePlateCover.height : 0);
  }

  @Watch('fenceItem', {deep: true})
  fenceItemChanged(value: FenceItemModel, oldValue: FenceItemModel): void {
    value.field.totalFenceHeight = this.calculateTotalFenceHeight;
    this.generatePlateSettingsModalArray();
  }
}
