
import {Component, Prop, Emit, Vue, Watch, Model} from 'vue-property-decorator';

@Component
export default class InputSwitch extends Vue{

  @Model('change', {
    default: false,
  })
  readonly active: boolean;

  @Prop({
    default: null
  })
  readonly label: string;

  activated = false;

  mounted(): void {
    this.activated = this.active;
  }

  @Emit('change')
  toggle(): boolean {
    this.activated = !this.activated;
    return this.activated;
  }

  @Watch('active')
  activeStateChanged(newVal: boolean): void {
    if(newVal !== this.activated) {
      this.toggle();
    }
  }
}
