// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {Texture} from "@/util/textures";
import { convertToObject } from "typescript";

export enum FenceItemType {
    FenceField, FenceDoor
}

export interface FenceItemFieldProperty {
    name: string;
    price: string;
    productNumber: string;
    picture: string;
    texture: Texture;
}

export class FencePlateCover {
    height: number;
    productNumber: string;
    type: FenceCoverType;
    color: FenceCoverColor;
}

export enum FencePlatePattern {
    unilateral = 'unilateral', bilateral = 'bilateral', none = 'none'
}

export class FencePlate {
    height?: number;
    pattern?: FencePlatePattern;
    productNumber: string;
    settingsOpen?: boolean | null;
    color?: string | null;
    ralColor?: string | null;
}

export enum FenceCoverType {
    flat = "flat",
    pyramid = "pyramid",
    none = "none"
}

export enum FenceCoverColor {
    naturgrau = "naturgrau",
    anthrazit = "anthrazit"
}

export class FencePostCover {
    height: number;
    productNumber: string;
    type: FenceCoverType;
    postType: FencePostType;
    color: FenceCoverColor;
}

export enum FencePostType {
    corner,
    intermediate,
    end
}

export interface heights {
    height:Array<number>
}

export class Properties{
    static heights: {heights} = require("../src/assets/props/heights.json");

    static getHeightOptions(height: number): Array<number>
    {
        return this.heights[height]?this.heights[height].sort((a,b)=>{return a-b}):[];
    }

    static getPlateHeightOptions():Array<string>
    {
        return Object.keys(this.heights)
    }
}

export class FencePost {
    height: number;
    productNumber: string;
    type: FencePostType;
    color?: FenceCoverColor;
}

export class FenceItemField {
    leftPost: FencePost;
    rightPost: FencePost;

    leftPostCover: FencePostCover;
    rightPostCover: FencePostCover;

    fencePlateCover: FencePlateCover;
    plates: Array<FencePlate>;

    series: string;
    platePattern: FencePlatePattern;

    /* in cm*/
    plateHeight: number;
    fenceHeight: number;
    totalFenceHeight: number;

    get plateHeightOptions(): Array<string> {
        return Properties.getPlateHeightOptions();
    }

    get fenceHeightOptions(): Array<number> {
        return Properties.getHeightOptions(this.plateHeight);
    }

    get fencePlateNumber(): number {
        return this.fenceHeight / this.plateHeight;
    }
}

export interface FenceItemDoor {
    name: string;
    price: string;
    productNumber: string;
}

export interface FenceItemPosition {
    start: {
        x: number;
        y: number;
    };
    end: {
        x: number;
        y: number;
    }
}

export interface FenceItemModel {
    id: number;
    type: FenceItemType;
    field?: FenceItemField;
    door?: FenceItemDoor;
    position: FenceItemPosition;
    leftFenceItem?: FenceItemModel;
    rightFenceItem?: FenceItemModel;
}

export interface ProductModel {
    name: string;
    label: string;
    productNumber: string;
    id: number;
    type: string;
    postType: FencePostType | null;
    coverType: FenceCoverType | null;
    series: string;
    file: string;
    height: number | null;
    pattern: FencePlatePattern | null;
    price: string | null;
    quantity: number | null;
    status: string | null;
    color: FenceCoverColor | null;
}

export interface WooProduct {
    id: number;
    name: string;
    slug: string;
    permalink: string;
    date_created: string;
    date_create_gmt: string;
    date_modified: string;
    date_modified_gmt: string;
    type: string;
    status: string;
    featured: string;
    catalog_visibility: string;
    description: string;
    short_description: string;
    sku: string;
    price: string;
    regular_price: string;
    sale_price: string,
    date_on_sale_from: string;
    date_on_sale_from_gmt: string;
    date_on_sale_to: string;
    date_on_sale_to_gmt: string;
    on_sale: boolean,
    purchasable: boolean;
    total_sales: number;
    virtual: boolean;
    downloadable: boolean;
    downloads: Array<any>;
    download_limit: number;
    download_expiry: number;
    external_url: string;
    button_text: string;
    tax_status: string;
    tax_class: string;
    manage_stock: boolean;
    stock_quantity: number;
    backorders: string;
    backorders_allowed: boolean;
    backordered: false;
    low_stock_amount: string;
    sold_individually: boolean;
    weight: string;
    dimensions: any;
    shipping_required: boolean;
    shipping_taxable: boolean;
    shipping_class: string;
    shipping_class_id: number;
    reviews_allowed: boolean;
    average_rating: string;
    rating_count: number;
    upsell_ids: Array<any>;
    cross_sell_ids: Array<any>;
    parent_id: number;
    purchase_note: string;
    categories: Array<any>;
    tags: Array<any>;
    images: Array<any>;
    attributes: Array<any>;
    default_attributes: Array<any>;
    variations: Array<any>;
    grouped_products: Array<any>;
    menu_order: number;
    price_html: string;
    related_ids: Array<number>;
    meta_data: Array<any>;
    stock_status: string;
    _links: Array<any>;
}

export class ProductHelper {
    static productsMap: Array<ProductModel> = require('@/assets/products.json');
    static productsList: Array<WooProduct> = [];
    static apiHost = 'betonzaun.de'; //TODO: make a
    static fetched = false;

    static async initialize(): Promise<void> {
        console.debug('Fetching product list from server...');
        await this.fetchProductsList();
        console.debug('Processing product map and product list...');
        await this.processProductMap();
        this.fetched = true;
    }

    static async fetchProductsList(): Promise<void> {
        //empty Product List on reload
        if(this.productsList.length > 0) {
            this.productsList = [];
        }
        let productsBuffer: Array<WooProduct> = [];
        const maxPages = 10;
        for(let i = 1; i < maxPages; i++) {
            await fetch('https://'+this.apiHost+'/wp-json/public-woo/v1/products?per_page=100&page='+i).then(resp => resp.json()).then(data => productsBuffer = data).catch(error => console.debug(error));
            if(productsBuffer.length === 100) {
                this.productsList.push(...productsBuffer);
                productsBuffer = [];
            }else if(productsBuffer.length > 0){
                this.productsList.push(...productsBuffer);
                productsBuffer = [];
                break;
            }else{
                break;
            }
        }
    }

    static async processProductMap(): Promise<void> {
        for(let i = 0; i < this.productsMap.length; i++) {
            // eslint-disable-next-line prefer-const
            let product: ProductModel = this.productsMap[i];
            // eslint-disable-next-line prefer-const
            let productListItem = this.productsList.find(value => value.sku === product.productNumber);
            if(productListItem !== undefined) {
                product.price = productListItem.price;
                product.quantity = productListItem.stock_quantity;
                product.status = productListItem.status;
                product.id = productListItem.id;
                this.productsMap[i] = product;
            }
        }
    }

    static getProduct(productNumber: string): ProductModel | null {
        return this.productsMap.find(value => value.productNumber === productNumber);
    }

    static getDoor(height: number): ProductModel | null {
        return this.productsMap.find(value => value.type === 'door' && value.height === height);
    }

    static getPlateOptions(series: string, height: number, pattern: FencePlatePattern): Array<ProductModel> {
        return this.productsMap.filter(value => value.series === series && value.height == height && value.pattern == pattern && value.type === 'plate');
    }

    static getPlateProdNumber(label:string,height:number,series:string): string | null
    {
        const prodNumber = this.productsMap.filter(value => value.label === label && value.height == height && value.series === series)
        if(prodNumber.length > 0){
            return prodNumber[0].productNumber
        } else {
            return null
        }
    }

    static getPost(series: string, height: number, postType: FencePostType, color: FenceCoverColor, ): Array<ProductModel> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.productsMap.filter(value => value.series === series && value.type === 'post' && value.height === "" + height && value.postType === FencePostType[postType] && value.color === FenceCoverColor[color]);
    }

    static getPostProdNumber(height: number, postType: FencePostType, color: FenceCoverColor, ): string | null{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const products = this.productsMap.filter(value => value.type === 'post' && value.height == height && value.postType === FencePostType[postType] && value.color === FenceCoverColor[color]);
        if(products.length > 0 ) {
            return products[0].productNumber;
        }else{
            return null
        }
    }

    static getPostOptions(series: string, height: number, postType: FencePostType | string): Array<ProductModel> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.productsMap.filter(value => value.series === series && value.height === ""+height && FencePostType[value.postType] == postType && value.type === 'post');
    }

    static getPlateCoverOptions(series: string, coverType: FenceCoverType | string, color?: FenceCoverColor | string): Array<ProductModel> {
        if(color == null) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.productsMap.filter(value => value.series === series && value.coverType === FenceCoverType[coverType] && value.type === 'plate-cover');
        } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.productsMap.filter(value => value.series === series && value.coverType === FenceCoverType[coverType] && FenceCoverColor[color] == value.color && value.type === 'plate-cover');
        }
    }

    static getPostCoverOptions(series: string, coverType: FenceCoverType | string, postType: FencePostType, color: FenceCoverColor | string): Array<ProductModel> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return this.productsMap.filter(value => value.series === series && FencePostType[value.postType] == postType && value.coverType === FenceCoverType[coverType] && FenceCoverColor[color] === value.color && value.type === 'post-cover');
    }
}

export interface PopupAction {
    name: string;
    // eslint-disable-next-line @typescript-eslint/ban-types
    callback: Function;
}

export enum PopupType {
    Info, InfoAction, MandatoryAction,Save,Rename, Import, Export
}

export interface PopupSettings {
    title: string;
    description: string;
    actions: Array<PopupAction>;
    closable: boolean;
    type: PopupType;
}

export interface PopupInstance extends PopupSettings {
    id: number;
    open: boolean;
}
